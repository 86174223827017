import 'alpinejs'
import EmblaCarousel from "embla-carousel";

declare global {
  interface Window {
    slideshow:any;
    slider:any;
  }
}

window.slider = () => {
  return {
    embla: '',

    init() {
      this.embla = EmblaCarousel(this.$refs.viewport, {
        align: 'center',
        containScroll: 'keepSnaps',
        loop: true,
      })
    }

  }
}

window.slideshow = () => {
  return {
    embla: '',

    goNext() {
      this.embla.scrollNext()
    },

    goPrev() {
      this.embla.scrollPrev()
    },

    init() {
      this.embla = EmblaCarousel(this.$refs.viewport, {
        align: 'start',
        containScroll: 'keepSnaps',
        loop: true,
      })
    }
  }
}