import '../../functions';
import Alpine from 'alpinejs'
import Lenis from '@studio-freight/lenis';
import { gsap, ScrollTrigger } from '../gsap';

export default function() {
  window.Alpine = Alpine
  Alpine.start()

  const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
    smooth: true,
  })

  lenis.on('scroll', (e) => {
    // console.log(e)
    ScrollTrigger.update()
  })

  window.lenis = lenis;

  function raf(time) {
    lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf);

  const $tickerWrapper = document.querySelector('.c-ticker__wrapper');
  if($tickerWrapper ) {
    const $list = $tickerWrapper.querySelector("ul");
    const $clonedList = $list.cloneNode(true);
    const listWidth = Array.from( $list.querySelectorAll('li')).reduce((acc, b) => acc + b.clientWidth, 10);

    $clonedList.classList.add("cloned");
    $tickerWrapper.appendChild($clonedList);

    const tl = gsap.timeline({repeat: -1, paused: true});
    const time = 40;

    tl
    .fromTo($list, time, {x:0}, {force3D:true, x: -listWidth, ease: "none"}, 0)
    .fromTo($clonedList, time, {x:listWidth}, {force3D:true, x:0, ease: "none"}, 0)
    .set($list, {force3D:true, x: listWidth})
    .to($clonedList, time, {force3D:true, x: -listWidth, ease: "none"}, time)
    .to($list, time, {force3D:true, x: 0, ease: "none"}, time)
    .progress(1).progress(0)
    .play();
  }

  if( window.innerWidth > 760) {

  gsap.utils.toArray('[data-scroll]').forEach((el) => {
    const parent = el.closest('[data-scroll-parent]');
    const direction = el.getAttribute('data-scroll-direction') || "vertical";
    gsap.to(el, {
      yPercent: direction === "vertical" ? 50 * parseFloat(el.getAttribute('data-scroll-speed')) : null,
      xPercent: direction === "horizontal" ? 50 * parseFloat(el.getAttribute('data-scroll-speed')) : null,

      scrollTrigger: {
        ease: "none",
        trigger: parent,
        invalidateOnRefresh: true,
        scrub: true,
      },
    });

  });
}
}