import { html } from './js/utils/environment';
import globals from './js/globals';

document.addEventListener('readystatechange', event => {
  if (event.target.readyState === "complete") {
    init();
  }
});

function init() {
  console.log("init")
  globals();

  html.classList.remove('dom-is-loading');
  html.classList.remove('is-loading');
  html.classList.add('dom-is-ready');
  html.classList.add('is-loaded');

  setTimeout(() => {
    html.classList.add('is-ready');
  }, 300);

  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click',  (e) => {
        e.preventDefault();

        const target = e.target.dataset.anchor;
          if( target ) {
            const scrollTo = (document.querySelector(`#${target}`));
            // window.Application.scroller.scrollTo(scrollTo);
          }
      });
  });

  document.querySelector('.c-menu-toggle').addEventListener('click', (e) => {
    document.documentElement.classList.toggle('menu-is-open');
    e.preventDefault();
  });

  let cbox = document.querySelectorAll(".c-backtop button");
  cbox.forEach(box => {
    // box.addEventListener('click', () => window.Application.scroller.scrollTo(0));
  });

  console.info('🚀App:init');
}